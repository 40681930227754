import React, { useEffect } from "react";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";
import Header from "../components/header";
import { aboutMain, aboutBranding } from "../../assests/media-constants";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-page">
      <Header />
      <div className="main-heading">
        <h1 className="mb-5 pb-5 text-center">ABOUT US</h1>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 ps-0 position-relative">
              <img
                src={aboutMain}
                className="w-100 main-image"
                alt="about us"
              />
              <img
                src={aboutBranding}
                className=" branding-image"
                alt="about us"
              />
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <h3 className="mt-5 fw-bold mb-4 pb-2">
                  Almond Media in a nutshell
                </h3>
                <p>
                  Historically the almond is a symbol of watchfulness and
                  promise and these are traits that Almond Media strives to
                  deliver for its partners of all types, ranging from duty of
                  care and our commitment to service quality, consistency and
                  payments.
                </p>
                <p>
                  With quality at its core, Almond Media has extracted the best
                  publishers from the industry and consolidated the best of
                  publishers in the market in one place.
                </p>
                <p>
                  We have removed the superfluous noise, so that all that
                  remains is the quality core extract. We are a proud boutique
                  agency. Instead of having thousands of publishers, we have
                  handpicked a select set of excellent and professional partners
                  that have proven to deliver outstanding results for many
                  advertisers and our own sites.
                </p>
                <p>Almond Media – quality, care and consistency.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-section">
        <ContactUs />
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </div>
  );
};

export default About;
