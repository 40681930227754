import React, { useEffect } from "react";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";
import Header from "../components/header";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contact-us-page">
      <Header />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default ContactUsPage;
