import React, { useEffect } from "react";
import {
  serviceImage1,
  serviceImage2,
  serviceImage3,
  serviceImage4,
} from "../../assests/media-constants";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";
import Header from "../components/header";
import { useLocation } from "react-router-dom";

const Services = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const scrollToSection = queryParams.get("to");
  useEffect(() => {
    if (scrollToSection) {
      const element = document.getElementById(scrollToSection);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [scrollToSection]);
  return (
    <div className="services-pages">
      <Header />
      <div id="performance" className="main-heading">
        <h1 className="mb-5 pb-5">SERVICES</h1>
      </div>
      <div className="content-section pb-5">
        <div className="container-fluid">
          <div className="row my-5 py-5">
            <div className="col-lg-5 ps-0">
              <img src={serviceImage1} className="w-100" alt="service-iamge" />
            </div>
            <div className="col-lg-7">
              <div className="content-control-odd">
                <h3>PERFORMANCE MARKETING</h3>
                <p>
                  Historically the almond is a symbol of watchfulness and
                  promise and these are traits that Almond Media strives to
                  deliver for its partners of all types, ranging from duty of
                  care and our commitment to service quality, consistency and
                  payments.
                </p>
                <p>
                  With quality at its core, Almond Media has extracted the best
                  publishers from the industry and consolidated the best of
                  publishers in the market in one place.
                </p>
                <p>
                  We have removed the superfluous noise, so that all that
                  remains is the quality core extract. We are a proud boutique
                  agency. Instead of having thousands of publishers, we have
                  handpicked a select set of excellent and professional partners
                  that have proven to deliver outstanding results for many
                  advertisers and our own sites.
                </p>
                <p>Almond Media - quality, care and consistency.</p>
              </div>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-lg-7">
              <div className="content-control-even">
                <h3 id="publisher">PUBLISHER CONTENT MANAGER</h3>
                <p>
                  Historically the almond is a symbol of watchfulness and
                  promise and these are traits that Almond Media strives to
                  deliver for its partners of all types, ranging from duty of
                  care and our commitment to service quality, consistency and
                  payments.
                </p>
                <p>
                  With quality at its core, Almond Media has extracted the best
                  publishers from the industry and consolidated the best of
                  publishers in the market in one place.
                </p>
                <p>
                  We have removed the superfluous noise, so that all that
                  remains is the quality core extract. We are a proud boutique
                  agency. Instead of having thousands of publishers, we have
                  handpicked a select set of excellent and professional partners
                  that have proven to deliver outstanding results for many
                  advertisers and our own sites.
                </p>
                <p>Almond Media - quality, care and consistency.</p>
              </div>
            </div>
            <div className="col-lg-5 pe-0">
              <img src={serviceImage2} className="w-100" alt="service-iamge" />
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-lg-5 ps-0">
              <img src={serviceImage3} className="w-100" alt="service-iamge" />
            </div>
            <div className="col-lg-7">
              <div className="content-control-odd pt-2">
                <h3 id="project">
                  PROJECT <br /> HARNESS
                </h3>
                <p>
                  Harness the power of the top UK email publishers at the same
                  time with guaranteed send times from 10 top publishers. You
                  can control volume, send size, timing and optimise this for
                  launches and branding.
                </p>
                <p>
                  • Harness the power of the top email partners to launch or
                  power your acquisition campaign to a new level.• Millions of
                  opt-in emails sent from at the same time or at a schedule as
                  determined by you.• Take complete control of the campaign.
                </p>
                <p>
                  Harness is charged on a fixed fee. Contact us for more
                  details.
                </p>
                <p>Almond Media - quality, care and consistency.</p>
              </div>
            </div>
          </div>
          <div className="row my-5 py-5 mb-0">
            <div className="col-lg-7">
              <div className="content-control-even pt-5">
                <h3 id="mobile">MOBILE / DISPLAY</h3>
                <p>
                  Almond Media drives vertical led traffic from its own websites
                  and also its publisher partner sites on its network for
                  desktop and mobile. In addition, we also purchase desktop and
                  mobile traffic on our clients' behalf via various RTB
                  platforms.
                </p>
                <p>
                  To find out more about how we can help you drive traffic to
                  your site in any vertical, be sure to contact us at
                  advertisers@almondmedia.co.uk. If you would like to be part of
                  our high quality mobile network please do also get in touch at
                  the same email address.
                </p>
                <p>Almond Media - quality, care and consistency.</p>
              </div>
            </div>
            <div className="col-lg-5 pe-0">
              <img src={serviceImage4} className="w-100" alt="service-iamge" />
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-section">
        <ContactUs />
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </div>
  );
};

export default Services;
