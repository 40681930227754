import React, { useEffect } from "react";
import { hamburger, logo } from "../../assests/media-constants";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";
import Header from "../components/header";

const Technology = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="technology-page">
      <div className="technology-content">
        <Header />
        <div className="technology-typography">
          <div className="container">
            <h1 className="mb-5">TECHNOLOGY</h1>
            <p>
              Almond Media uses the “best in breed” tracking technology to
              ensure accurate tracking, fraud prevention and campaign
              management.
            </p>
          </div>
        </div>
      </div>

      <div className="contact-us-section">
        <ContactUs />
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </div>
  );
};

export default Technology;
