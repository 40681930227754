import React, { useRef, useState } from "react";
import { contactUsBranding } from "../../assests/media-constants";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const form = useRef();
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isCaptchaVerified) {
      emailjs
        .sendForm(
          "service_ryos7fw",
          "template_trmfp1o",
          form.current,
          "TalJZ7XFj_s16qiXA"
        )
        .then(
          (result) => {
            console.log(result.text);
            alert("Message Sent");
          },
          (error) => {
            console.log(error.text);
            alert("Message not Sent");
          }
        );
    } else {
      alert("Please complete the CAPTCHA.");
    }
  };
  return (
    <div className="contact-us-component">
      <div className="container">
        <div className="contact-us-header mb-5">
          <h1>CONTACT US</h1>
          <img src={contactUsBranding} alt="branding image" />
        </div>
        <div className="contact-us-content">
          <div className="container">
            <form ref={form} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <input
                    required
                    type="text"
                    name="user_name"
                    placeholder="Your Name (Required)"
                    className="form-control input-field"
                  />
                </div>
                <div className="col-lg-6 mb-4">
                  <input
                    type="email"
                    required
                    name="user_email"
                    placeholder="Your Email (Required)"
                    className="form-control input-field"
                  />
                </div>
                <div className="col-lg-12 mb-4">
                  <textarea
                    name="message"
                    required
                    placeholder="Your Message"
                    rows={9}
                    className="form-control input-field"
                  />
                </div>
                {/* ... (existing form fields) */}
                <div className="col-lg-12">
                  <ReCAPTCHA
                    sitekey="6LdFJzQpAAAAAKzD5DmNmJz2zR2ShlAZMtsze1kK"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <div className="col-lg-12 text-end mt-2 mb-5">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-light submit-button"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
