import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./layout/pages/Home";
import Services from "./layout/pages/Services";
import Technology from "./layout/pages/Technology";
import ContactUs from "./layout/pages/contactUs";
import News from "./layout/pages/News";
import About from "./layout/pages/About";
import Terms from "./layout/pages/Terms";
import PrivacyPolicy from "./layout/pages/Privacy";

import "./assests/css/service.css";
import "./assests/css/technology.css";
import "./assests/css/components.css";
import "./assests/css/news.css";
import "./assests/css/about.css";
import "./assests/css/policy.css";
import "./assests/css/home.css";

const MainRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/news" element={<News />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default MainRoutes;
