import React, { useEffect } from "react";
import Header from "../components/header";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="policy-page">
      <Header />
      <div className="main-heading">
        <h1 className="mb-5 pb-5 text-center mt-5">PRIVACY AND POLICY</h1>
      </div>
      <div className="content pb-5">
        <div className="container">
          <h2 className="text-upper fw-bold mb-4">Who we are</h2>
          <p>
            Almond Media Group Ltd (hereinafter called Almond Media) is a media
            agency, providing advertising and lead generation services to our
            advertiser clients and conducts the following principal activities:
            Mobile marketing services, electronic mail marketing, web publicity
            displays, advertising campaigns and affiliate marketing to promote a
            wide range of services and/or products on behalf of ourselves and/or
            third parties. These third parties we typically refer to as our
            clients, partners, business partners, affiliates and we/they may
            operate in any business sector.
          </p>
          <p>
            We also collect and archive data for research purposes for public
            benefit and/or interest.
          </p>
          <p>
            Furthermore we may process your data in order to provide you with
            the services you have requested or, where you have given us consent
            to do so, to send you information that we think you’ll find
            interesting and we store your data on what are considered secure
            servers.
          </p>
          <p>
            We work within the guidelines and advice provided by the ICO, the
            regulator of the Data Protection Act 2018 and within GDPR and
            Australian Privacy Principles (APP) guidelines.
          </p>
          <p>
            We aim to make this privacy policy as simple as possible in plain
            language and for your benefit utilising the recommended minimisation
            protocol.
          </p>
          <p>
            This policy outlines how Almond Media will use the information
            (known as data) that you provide, whether by completing a contact
            form, a registration form, a request form, a membership form or
            using any part of its website/s.
          </p>
          <h2 className="text-upper fw-bold mt-5 mb-4">
            How and Where Your Data Is Stored
          </h2>
          <p>
            Almond Media Group Ltd store user data within the EU on secure
            severs. We take appropriate technical and organisational measures to
            try to secure your information and to protect it against
            unauthorised or unlawful use and accidental loss or destruction,
            including:
          </p>
          <ul>
            <li>
              only sharing and providing access to your information to the
              minimum extent necessary, subject to confidentiality restrictions
              where appropriate, and on an anonymised basis wherever possible.
            </li>
            <li>using secure servers to store your information;</li>
            <li>
              verifying the identity of any individual who requests access to
              information prior to granting them access to information
            </li>
          </ul>
          <h2 className="text-upper fw-bold mb-4 mt-5">
            Company Information and Definitions
          </h2>
          <ul>
            <li>Almond Media Group Ltd referred to herein as Almond Media</li>
            <li>Company Registration Number: 11958245 Registered in the UK</li>
            <li>Website address: http://almond media.co.uk</li>
            <li>Address: 159G Porchester Terrace North, London W2 6BH</li>
            <li>GDPR (General Data Protection Regulation)</li>
            <li>APP (Australia Privacy Policy)</li>
            <li>
              Data Controller is: Almond Media Group Ltd. You may contact the
              data controller at: admin@almondmedia.co.uk
            </li>
            <li>
              Data Processor:Contact the Data Processor at
              admin@almondmedia.co.uk
            </li>
          </ul>
          <h2 className="text-upper fw-bold mb-4 mt-5">
            Principles for processing personal data
          </h2>
          <p>
            Our aims for processing your personal data are as follows: We may
            collect your personal data to help us understand your needs and
            guide the way in which we communicate/interact with you about ours
            and third parties’ products and services (subject to your consent).
            We aim to comply with policies and procedures that are in place to
            protect the data (information) that you provide when registering to
            our site/s, and we process it for the purpose of providing you with
            the services you have requested and/or where you have given us your
            permission to do so; to send you information that we think you will
            find interesting or useful. Whenever we save data, our aim is for
            your information to be stored on what are considered secure servers.
            We seek to comply within the guidelines/advice made available by:
          </p>
          <ul>
            <li>
              CO (Information Commissioner’s Office) Data Protection Act 2018
            </li>
            <li>GDPR</li>
            <li>APP (Australia Privacy Principles)</li>
          </ul>
          <ol>
            <li>
              Fairness and lawfulness. When we process personal data, our aim is
              for individual rights of Data Subjects to be protected.
            </li>
            <li>
              Restricted to a specific purpose. The personal data of a Data
              Subject will be processed for specific purposes.
            </li>
            <li>
              Transparency. The Data Subject can, upon request be informed of
              how your data is being collected, processed, used.
            </li>
          </ol>
        </div>
      </div>

      <div className="footer-section mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
