import React, { useState } from "react";
import { hamburger, homeHeaderBg, logo } from "../../assests/media-constants";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  let headerLinks = [
    { name: "HOME", link: "/" },
    { name: "ABOUT US", link: "/about" },
    { name: "SERVICES", link: "/services" },
    { name: "TECHNOLOGY", link: "/technology" },
    { name: "NEWS", link: "/news" },
    { name: "CONTACT US", link: "/contact-us" },
    { name: "PRIVACY POLICY", link: "/privacy" },
    { name: "TERMS AND CONDITION", link: "/terms" },
  ];
  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-8">
            {" "}
            <img
              onClick={() => navigate("/")}
              src={logo}
              className="main-logo"
              alt="logo"
            />
          </div>
          <div className="col-4 text-end align-self-center">
            <div
              className={`menu-icon ${menuOpen ? "open" : ""}`}
              onClick={handleMenuClick}
            >
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </div>

      {menuOpen && (
        <div className="menu">
          <img
            src={homeHeaderBg}
            className="header-menu-image"
            alt="header image"
          />
          <div className="container">
            <div className="close-icon" onClick={closeMenu}>
              &times;
            </div>
            <ul>
              {headerLinks.map((item, index) => {
                return (
                  <Link
                    key={index}
                    className={"text-decoration-none"}
                    to={`${item.link}`}
                  >
                    <li
                      style={
                        item?.link == window.location.pathname
                          ? {
                              borderBottom: "5px solid #EAA053",
                            }
                          : null
                      }
                    >
                      {item.name}
                    </li>
                  </Link>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
