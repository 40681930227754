import React, { useEffect } from "react";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";
import Header from "../components/header";
import {
  branding,
  contactUsBranding,
  ellipse,
  homeImage,
  homeService1,
  homeService2,
  homeService3,
  homeService4,
  homeServiceBack,
} from "../../assests/media-constants";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home-page">
      <div className="content">
        <div
          className="banner-scetion"
          style={{ backgroundImage: `url("${homeImage}")` }}
        >
          <div className="header-section">
            <Header />
          </div>
          <div className="get-in-touch">
            <div>
              <button className="btn btn-light home-btn mt-3">
                GET IN TOUCH
              </button>
            </div>
          </div>
          <div className="container">
            <div className="row position-relative">
              <div className="social-media">
                <div className="link-new">
                  <a className="links-social" href="#">
                    FACEBOOK
                  </a>
                </div>
                <div className="link-new">
                  <a className="links-social" href="#">
                    TWITTER
                  </a>
                </div>
                <div className="link-new">
                  <a className="links-social" href="#">
                    INSTAGRAM
                  </a>
                </div>
              </div>

              <div className="col-9">
                <h1 className="text-upper pt-5 mt-5">
                  Quality, Care <br /> and Consistency
                </h1>
              </div>
              <div className="col-3 text-center">
                <img
                  src={contactUsBranding}
                  alt="branding"
                  className="home-branding mt-4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="who-we-are">
          <div className="container my-5 py-5">
            <div className="row">
              <div className="col-md-4 mt-5">
                <h1>
                  WHO <br /> WE ARE
                </h1>
              </div>
              <div className="col-md-2">
                <img src={contactUsBranding} className="home-who-we-img" />
              </div>

              <div className="col-md-6">
                <p>
                  <i>
                    Almond Media is a boutique agency that provides a select
                    group of proven advertisers and publishers with high quality
                    service. We focus on consistency, quality and care and aim
                    to give all parties an amazing and easy experience. Our aim
                    is to foster long term partnerships that deliver value for
                    all.
                  </i>
                </p>
                <p>
                  <i>
                    With years of industry experience, we focus on the most
                    excellent players in the industry through years of testing
                    the best publishers in the market. We also only work with
                    selected advertisers who deliver real value for our
                    publishers, facilitating best of breed partnerships.
                  </i>
                </p>
                <p>
                  <i>Our motto is simple. In a nutshell:</i>
                </p>
                <p>
                  <i>Almond Media – quality, care and consistency.</i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="service-section">
          <div className="container pt-1">
            <div className="row">
              <div className="col-6">
                <h1 className="mt-5 pt-5 mb-5">SERVICES</h1>
              </div>
              <div className="col-6 text-end align-self-center">
                <button
                  onClick={() => navigate("/services")}
                  className="btn btn-light home-btn mt-3"
                >
                  View All Services
                </button>
              </div>
            </div>
            <div className="services-container mt-5">
              <div
                style={{ backgroundImage: `url("${homeService1}")` }}
                className="service-box"
              >
                <div className="content">
                  <h4>Performance</h4>
                  <h3>Marketing</h3>
                  <img
                    onClick={() => navigate("/services?to=performance")}
                    src={homeServiceBack}
                    className="back-btn-sercvie"
                    alt="back button"
                  />
                </div>
              </div>
              <div
                style={{ backgroundImage: `url("${homeService2}")` }}
                className="service-box"
              >
                <div className="content">
                  <h4>PUBLISHER CONTENT </h4>
                  <h3>MANAGER</h3>
                  <img
                    onClick={() => navigate("/services?to=publisher")}
                    src={homeServiceBack}
                    className="back-btn-sercvie"
                    alt="back button"
                  />
                </div>
              </div>
              <div
                style={{ backgroundImage: `url("${homeService3}")` }}
                className="service-box"
              >
                <div className="content">
                  <h4>PROJECT</h4>
                  <h3>HARNESS</h3>
                  <img
                    onClick={() => navigate("/services?to=project")}
                    src={homeServiceBack}
                    className="back-btn-sercvie"
                    alt="back button"
                  />
                </div>
              </div>
              <div
                style={{ backgroundImage: `url("${homeService4}")` }}
                className="service-box"
              >
                <div className="content">
                  <h4>MOBILE /</h4>
                  <h3>DISPLAY</h3>
                  <img
                    onClick={() => navigate("/services?to=mobile")}
                    src={homeServiceBack}
                    className="back-btn-sercvie"
                    alt="back button"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-section">
        <ContactUs />
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
