import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className="footer-content">
              Copyright © 2023 almondmedia. All Rights Reserved
            </p>
          </div>
          <div className="col-md-6 text-end">
            <Link to={"/terms"}>
              <span className="footer-content me-5">Terms of Use</span>
            </Link>
            <Link to={"/privacy"}>
              <span className="footer-content ms-5">Privacy Policy</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
