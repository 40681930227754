import React, { useEffect } from "react";
import Header from "../components/header";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="policy-page">
      <Header />
      <div className="main-heading">
        <h1 className="mb-5 pb-5 text-center mt-5">TERMS AND CONDITION</h1>
      </div>
      <div className="content">
        <div className="container">
          <h2 className="text-upper fw-bold mb-4 mt-5">
            Almond Media Group Ltd
          </h2>
          <p>
            These Terms and Conditions (hereinafter also known as T & Cs, Terms,
            Conditions) outline your allowable use of and interaction with
            AlmondMedia.co.uk, Almond Media’s website/s as well as Almond
            Media’s app/s (application/s).
          </p>
          <p>
            Almond Media Group Ltd also trading as Almond Media, will also be
            referred to as “we” and “us” “our” and “company”. When we refer to
            “you”, with regard to any breach of these T & Cs, this means you as
            an individual acting on behalf of yourself, the company or
            organisation you work for or with, or represent. Therefore by using
            this site, “you” in its entirety as outlined above are equally
            liable under the law should these Terms and Conditions be breached.
          </p>
          <p>
            Please read these T & Cs carefully. If for any reason whatsoever you
            do not agree or understand these Terms, please do not use the
            company website and/or company app/s. Please do not subscribe,
            register or interact in any way via our website or app. Instead
            please leave the website or app immediately. Thank you.
          </p>
          <h4 className=" fw-bold mb-4 mt-5">Age and Minors</h4>
          <p>
            If you are under the age of 18 years (or if you are considered a
            minor in your country, state, or region even if over 18 years of
            age), you may not use this website or app. Please therefore leave
            this website and/or app immediately. Thank you.
          </p>
          <h4 className="fw-bold mb-4 mt-5">Usage</h4>
          <p>
            Almond Media’s websites and apps are provided to you subject to
            these T & Cs therefore by using the website or app, you agree to be
            bound by these T & Cs and by our Privacy Policy. You may also wish
            to view our cookie policy before proceeding.
          </p>
          <p>
            If you are given private access to any page on our website/s or
            app/s wherein a login or password is provided or required, these are
            to be considered private pages and you may not share your login or
            password with any other individual person or company. By accepting
            these T & Cs, you understand and agree the information contained
            within such pages is to remain confidential “for you eyes only” and
            is subject to copyright.
          </p>
          <h4 className="fw-bold mb-4 mt-5">
            You must not use the website/s or app/s for any of the following
            reasons:
          </h4>
          <p>
            In any way that constitutes a criminal offence. This includes but is
            not limited to:
          </p>
          <ul>
            <li>
              Transmitting material that may lead to any conduct that
              constitutes a criminal offence, or breaches any applicable laws,
              regulations and/or code of practice within the UK, European Union,
              Australia, United States of America, Canada and within
              International Law. Propogate, spread, publish, advertise or
              disseminate any Harassing, Threatening, Harmful, Obscene,
              Libellous, Abusive, Vulgar, Hateful, Discriminatory and/or other
              objectionable material. This includes attempted restriction of
              human rights under International and UK Law. We conduct periodic
              surveillance of our site to address any potential breaches of our
              Terms.
            </li>
          </ul>
          <p>
            You must not misuse our website/s or app/s by knowingly using piracy
            tactics of any sort whatsoever or by introducing distributed denial
            of service attacks (DoS), worms, viruses, trojans, logic bombs or
            other material which is malicious in any manner. You must not copy
            any part of our website or app (these are copyright protected).
            Furthermore, you must not attempt to gain unauthorised access to our
            websites or apps nor any servers we may use by any means or plant
            any code whatsoever on our website/s or app/s.
          </p>
          <ul>
            <li>
              Interfering with or restricting any individual’s (aged 18 or over
              subject to the Terms), any company’s, organisation’s or entity’s
              enjoyment and/or use of this website or app in any way.
            </li>
          </ul>
          <h4 className="fw-bold mb-4 mt-5">Our right to monitor</h4>
          <p>
            We reserve the right and may, under any circumstances, monitor your
            interaction with and/or communication with or on our website/s, our
            app/s, and with any third party via our website or app. By using our
            website or app, you agree to these Terms, you acknowledge,
            understand and agree this monitoring. We do this for safety,
            security and our business reasons to enhance our services and your
            usage of this website or app. We conduct periodic ongoing
            surveillance of our site.
          </p>
          <h4 className="fw-bold mb-4 mt-5">
            Website and/or App Availability{" "}
          </h4>
          <p>
            We make every reasonable effort to ensure the website and/or app is
            available for use and your data is secure as well as the services on
            our website and/or app. However we, like all other companies,
            organisations, entities including top Infosec organisations cannot
            categorically promise absolutely security despite our best efforts.
            Therefore we cannot be held liable for any such breach beyond our
            reasonable control.
          </p>
          <p>
            We may from time conduct maintenance or updates on our website/s
            and/or app/s and therefore our website/s and/or apps may not be
            available for a short period.
          </p>
          <p>
            If you notice any breach of our Terms or misuse of our website/s
            and/or app/s, please let us know: admin@almondmedia.co.uk. By using
            our website/s and or app/s, under these Terms, it is your duty to
            advise us of any breach or misuse immediately upon your discovery.
          </p>
        </div>
      </div>
      <div className="contact-us-section">
        <ContactUs />
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </div>
  );
};

export default Terms;
