import React, { useEffect } from "react";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";
import Header from "../components/header";
import { img1, img2, news1, news2, news3 } from "../../assests/media-constants";

const News = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="news-page">
      <Header />
      <div className="main-heading">
        <h1 className="mb-5 pb-5 text-center">NEWS</h1>
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-5 pb-3">
              <div className="card-news">
                <img src={img1} alt="news" className="w-100" />
                <p className="date">April 6, 2022</p>
                <h3 className="heading mb-3">
                  As the cookie crumbles, three strategies for advertisers to
                  thrive
                </h3>
                <a
                  target="_blank"
                  href="https://www.mckinsey.com/capabilities/growth-marketing-and-sales/our-insights/as-the-cookie-crumbles-three-strategies-for-advertisers-to-thrive"
                  className="read-more"
                >
                  READ MORE
                </a>
              </div>
            </div>
            <div className="col-md-4 mb-5 pb-3">
              <div className="card-news">
                <img src={img2} alt="news" className="w-100" />
                <p className="date">December 15th, 2023</p>
                <h3 className="heading mb-3">
                  Future – Proof Your Email Marketing Strategies with First –
                  and Zero – Party Data
                </h3>
                <a
                  target="_blank"
                  href="https://influencermarketinghub.com/future-proof-your-email-marketing-strategies-with-first-and-zero-party-data/"
                  className="read-more"
                >
                  READ MORE
                </a>
              </div>
            </div>
            <div className="col-md-4 mb-5 pb-3">
              <div className="card-news">
                <img src={news3} alt="news" className="w-100" />
                <p className="date">December 4, 2023</p>
                <h3 className="heading mb-3">
                  E-Mail A Winner This Holiday Season
                </h3>
                <a
                  target="_blank"
                  href="https://www.forbes.com/sites/walterloeb/2023/12/04/e-mail-a-winner-this-holiday-season/?sh=33ca6b6514c8"
                  className="read-more"
                >
                  READ MORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-section">
        <ContactUs />
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </div>
  );
};

export default News;
